import { Box } from '@xstyled/styled-components';
import { Article, ScreenContainer, Button } from "../../../../node_modules/smooth-doc/components";
import { Link } from "gatsby";
import notFoundImageURL from "../../../../node_modules/smooth-doc/images/404.png";
import * as React from 'react';
export default {
  Box,
  Article,
  ScreenContainer,
  Button,
  Link,
  notFoundImageURL,
  React
};