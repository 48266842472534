import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './styles.css'

const ToggleImageCard = ({dimage, mimage}) => {

  const DESKTOP_HEADING = 'Desktop Browsers'
  const MOBILE_HEADING = 'Mobile Browsers'
  const DESKTOP_BTN_LABEL = 'See Desktop'
  const MOBILE_BTN_LABEL = 'See Mobile'
  const MOBILE_MATCH = 'mobile'

  const [ btn, setBtn ] = useState(MOBILE_BTN_LABEL)
  const [ img, setImg ] = useState(dimage)
  const [ type, setType ] = useState(DESKTOP_HEADING)

  const handleToggle = () => {
    if (btn.toLowerCase().includes(MOBILE_MATCH)) {
      setBtn(DESKTOP_BTN_LABEL)
      setType(MOBILE_HEADING)
      setImg(mimage)
    }
    else {
      setBtn(MOBILE_BTN_LABEL)
      setType(DESKTOP_HEADING)
      setImg(dimage)
    }
  }

  return (
    <div className="toggle-card-wrap">
      <section className="button-wrap">
        <h2 className="card-heading">{type}</h2>
        <button className="dbutton" onClick={handleToggle}>{btn}</button>
      </section>
      <section className="image-wrap">
        <img src={img} alt={'IMAGE: ' + img} />
      </section>
    </div>
  )
}

export default ToggleImageCard

ToggleImageCard.propTypes = {
  dimage: PropTypes.string,
  mimage: PropTypes.string,
}
