import { ScreenContainer, Button, HeroSection, Hero, HeroBody, HeroTitle, HeroTeaser, HeroActionList, HeroAction } from "smooth-doc/components";
import heroBackgroundImageURL from "../../../../images/hero-background.png";
import * as React from 'react';
export default {
  ScreenContainer,
  Button,
  HeroSection,
  Hero,
  HeroBody,
  HeroTitle,
  HeroTeaser,
  HeroActionList,
  HeroAction,
  heroBackgroundImageURL,
  React
};