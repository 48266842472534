import ToggleImageCard from "../../../../pages/components/ToggleImageCard";
import dByCaliberImg from "../../../../pages/img/desktop-bycaliber.png";
import mByCaliberImg from "../../../../pages/img/mobile-bycaliber.png";
import dAmmoHandgunImg from "../../../../pages/img/desktop-ammohandgun.png";
import mAmmoHandgunImg from "../../../../pages/img/mobile-ammohandgun.png";
import * as React from 'react';
export default {
  ToggleImageCard,
  dByCaliberImg,
  mByCaliberImg,
  dAmmoHandgunImg,
  mAmmoHandgunImg,
  React
};